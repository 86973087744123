/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { css, List, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router';

type MenuProps = {
  changeId: string;
  pathname: string;
  isResourcesVisible: boolean | '' | null;
  isCalcVisible: boolean | '' | null;
  isExternVisible: boolean | '' | null;
  isImplementationVisible: boolean | '' | null;
};

const MainMenuList = observer(
  ({
    changeId,
    pathname,
    isResourcesVisible,
    isCalcVisible,
    isExternVisible,
    isImplementationVisible,
  }: MenuProps) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
      <List
        css={css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-top: 32px;
          font-size: 12px;
          & .MuiListItemButton-root {
            padding-left: 24px;
          }
        `}
      >
        <ListItemButton
          css={css`
            background-color: #c2e3f3;
          `}
          onClick={() => navigate(`/change-request/${changeId}`)}
        >
          {t('overview')}
        </ListItemButton>

        <ListItemButton
          css={css`
            background-color: ${pathname.includes('edit')
              ? 'rgba(0, 0, 0, 0.05)'
              : 'none'};
          `}
          onClick={() => navigate(`/change-request/${changeId}/edit`)}
        >
          Change Details
        </ListItemButton>

        {isResourcesVisible && (
          <ListItemButton
            css={css`
              background-color: ${pathname.includes('resources')
                ? 'rgba(0, 0, 0, 0.05)'
                : 'none'};
            `}
            onClick={() =>
              !pathname.includes('resources') &&
              navigate(`/change-request/${changeId}/resources`)
            }
          >
            {t('res')}
          </ListItemButton>
        )}

        {isCalcVisible && (
          <ListItemButton
            css={css`
              background-color: ${pathname.includes('calculation')
                ? 'rgba(0, 0, 0, 0.05)'
                : 'none'};
            `}
            onClick={() => navigate(`/change-request/${changeId}/calculation`)}
          >
            {t('calc')}
          </ListItemButton>
        )}

        {isExternVisible && (
          <ListItemButton
            css={css`
              background-color: ${pathname.includes('approval')
                ? 'rgba(0, 0, 0, 0.05)'
                : 'none'};
            `}
            onClick={() => navigate(`/change-request/${changeId}/approval`)}
          >
            {t('approvalStep')}
          </ListItemButton>
        )}
        {isImplementationVisible && (
          <ListItemButton
            css={css`
              text-transform: capitalize;
              background-color: ${pathname.includes('implementation')
                ? 'rgba(0, 0, 0, 0.05)'
                : 'none'};
            `}
            onClick={() =>
              navigate(`/change-request/${changeId}/implementation`)
            }
          >
            {t('implementation')}
          </ListItemButton>
        )}
      </List>
    );
  },
);

export default MainMenuList;
