/**
 * The external dependencies
 */

/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate, useParams } from 'react-router';
import { UpdateChangeDtoStatusEnum } from '@codefluegel/zeta-change-typescript-client';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Icon,
  TextField,
} from '@mui/material';

/**
 * The internal dependencies
 */
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { useAuth } from 'react-oidc-context';
import { useChangeApiFactory } from '../ApiClient';
import Section from '../components/Section/Section';
import { DialogType } from '../types/changes';
import TaskCardIconMap from '../utils/changes';
import ProcessItem from '../components/ProcessItems/ProcessItem';
import { useChangeStore } from '../store/changeStore';
import { useStorex } from '../store/UIStore';
import { EXCLUDED_STATUSES_FOR_RESOURCES } from '../constants/statusesForChecking';
import LoadSpinner from '../components/LoadSpinner/LoadSpinner';
import useLoading from '../utils/useLoading';
import NotificationMessages from '../constants/notification';
import { NotificationTypes } from '../types/notification';
import { TaskCardLocalizationList } from '../constants/changes';
import {
  checkIfRejected,
  userIsProjectViewer,
  useUserHasFullAccess,
} from '../utils/permissionHelper';
import ChangeStatusDialogWrapper from '../components/Dialog/ChangeStatusDialogWrapper';
import ButtonsGroup from '../components/ButtonsGroup/ButtonsGroup';
import { getDepartmentById } from '../utils/utils';

const ChangePage = observer(() => {
  const { change, reducedWorkPackages, isDataLoading, workPackages } =
    useChangeStore();
  const { setNotification } = useStorex();

  const TaskCardLocalization = TaskCardLocalizationList();
  const auth = useAuth();

  const { changeId } = useParams();
  const navigate = useNavigate();
  const changeApi = useChangeApiFactory();
  const { t } = useTranslation();

  const [modalType, setModalType] = useState<DialogType | null>(null);
  const [infoText, setInfoText] = useState('');
  const { toggleIsLoading } = useLoading();
  const NotificationMessage = NotificationMessages();

  let processes = change && change.processes;
  const isCheckStatus = change?.status === UpdateChangeDtoStatusEnum.Check;
  const isClosedStatus = change?.status === UpdateChangeDtoStatusEnum.Closed;

  const isOnlyProjectViewer = userIsProjectViewer(change);

  const showButtonGroup = isCheckStatus;
  const isRejected = checkIfRejected(change);

  const userHasFullAccess = useUserHasFullAccess(change);

  if (!userHasFullAccess) {
    processes =
      processes?.filter(process =>
        change?.commonProject?.navProcessToCommonProjectRelations.some(
          commonProcess =>
            commonProcess.id === process.pId &&
            (commonProcess.owner?.toLocaleLowerCase() ===
              auth.user?.profile.email?.toLocaleLowerCase() ||
              commonProcess.temporaryCoreTeamMember?.user.id.toLocaleLowerCase() ===
                auth.user?.profile.email?.toLocaleLowerCase()),
        ),
      ) ?? null;
  }

  useEffect(() => {
    (async () => {
      if (changeId && change) {
        switch (change.status) {
          case UpdateChangeDtoStatusEnum.Draft:
            setInfoText(t('creatChange'));
            break;
          case UpdateChangeDtoStatusEnum.New:
            setInfoText(t('checkChange'));
            break;
          case UpdateChangeDtoStatusEnum.NewRework:
            setInfoText(t('pleaseRework'));
            break;
          case UpdateChangeDtoStatusEnum.EvalComplete:
            setInfoText(t('ChangeOkText'));
            break;
          case UpdateChangeDtoStatusEnum.EvalRework:
            setInfoText(t('pleaseRework'));
            break;
          case UpdateChangeDtoStatusEnum.InEvaluation:
            setInfoText(
              t('evalUntil', {
                date: new Date(
                  change.submisionCRByClient ?? '',
                ).toLocaleDateString(),
              }),
            );
            break;
          default:
            break;
        }
      }
    })();
  }, [changeId, change, t]);

  const onChangeClick = useCallback(() => {
    navigate(`/change-request/${changeId}/edit`);
  }, [changeId, navigate]);

  const onProcessClick = useCallback(
    (processId: number) => {
      if (
        change &&
        change.status &&
        !EXCLUDED_STATUSES_FOR_RESOURCES.includes(change.status)
      ) {
        navigate(`/change-request/${changeId}/resources/${processId}`);
      }
    },
    [changeId, change, navigate],
  );

  const debouncedRequest = useDebouncedCallback(
    async (data: { [key: string]: string | number | null }) => {
      try {
        if (change) {
          toggleIsLoading();

          const { data: updatedChange } =
            await changeApi.changesControllerUpdate(change.id, {
              ...data,
              status: change.status as UpdateChangeDtoStatusEnum,
            });

          if (updatedChange) {
            setNotification({
              type: NotificationTypes.success,
              message: NotificationMessage.chagneUpdate,
            });
            toggleIsLoading();
          }
        }
      } catch (e: any) {
        setNotification({
          type: NotificationTypes.error,
          message: e.response.data.message,
        });
        toggleIsLoading();
        console.error(e);
      }
    },
    300,
  );

  const onChange = useCallback(
    (key: string, value: string | null) => {
      debouncedRequest({ [key]: value });
    },
    [debouncedRequest],
  );

  return (
    <Box>
      <Grid
        container
        direction="column"
        css={css`
          display: flex;
          padding: 19px 0px 12px 0px;
        `}
      >
        {infoText && !isOnlyProjectViewer && (
          <Typography
            variant="body1"
            color="primary"
            css={css`
              background: #ececec;
              color: #333333;
              padding: 8px;
              margin-bottom: 24px;
              font-size: 12px;
              max-width: 355px;
            `}
          >
            {infoText}
          </Typography>
        )}

        {isDataLoading && <LoadSpinner />}

        {!isDataLoading && (
          <Section
            search={false}
            Title={`Change ${change?.changeNo?.toString() || ''}`}
            css={css`
              justify-content: space-between;
              margin-top: 13px;
              margin-bottom: 14px;

              &:hover {
                cursor: pointer;
              }
            `}
            onClick={onChangeClick}
            Controls={undefined}
          >
            <Grid
              item
              css={css`
                position: absolute;
                top: 24px;
                right: 24px;
                display: flex;
                align-items: center;
              `}
            >
              <Icon>
                {TaskCardIconMap[change?.status as UpdateChangeDtoStatusEnum]}
              </Icon>

              <Typography variant="caption">
                {
                  TaskCardLocalization[
                    change?.status as UpdateChangeDtoStatusEnum
                  ]
                }
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              css={css`
                justify-content: space-between;
                width: 100%;
              `}
            >
              <Grid item xs={12} sm={6} lg={6}>
                {change?.title || ''}
                <Grid item>
                  <List
                    css={css`
                      margin-bottom: 7px;
                      padding-bottom: 0;
                      padding-top: 7px;
                    `}
                  >
                    <ListItem
                      alignItems="flex-start"
                      css={css`
                        padding-left: 0;
                        padding-bottom: 0;
                        padding-top: 2px;
                      `}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: '12px',
                          fontWeight: '600',
                        }}
                        primary={`${t('createdat')}:`}
                        css={css`
                          width: 200px;
                        `}
                      />
                      <ListItemText
                        secondaryTypographyProps={{ align: 'left' }}
                        secondary={
                          change?.createdAt &&
                          format(new Date(change?.createdAt), 'dd.MM.yyyy')
                        }
                        css={css`
                          width: 100%;
                        `}
                      />
                    </ListItem>
                    <ListItem
                      alignItems="flex-start"
                      css={css`
                        padding-left: 0;
                        padding-bottom: 0;
                      `}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          fontSize: '12px',
                          fontWeight: '600',
                        }}
                        primary={`${t('createdWho')}:`}
                        css={css`
                          width: 200px;
                        `}
                      />
                      <ListItemText
                        secondaryTypographyProps={{ align: 'left' }}
                        secondary={`${change?.author} | ${change?.initiator}`}
                        css={css`
                          width: 100%;
                        `}
                      />
                    </ListItem>
                    {(change?.status === UpdateChangeDtoStatusEnum.NewRework ||
                      change?.status ===
                        UpdateChangeDtoStatusEnum.EvalRework) && (
                      <ListItem
                        alignItems="flex-start"
                        css={css`
                          padding-left: 0;
                          padding-bottom: 0;
                          background: #80acd452;
                        `}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                          primary={t('ReasonToChange')}
                          css={css`
                            width: 200px;
                          `}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            align: 'left',
                          }}
                          secondary={change?.rejectionComment}
                          css={css`
                            width: 100%;
                          `}
                        />
                      </ListItem>
                    )}
                    {change?.status ===
                      UpdateChangeDtoStatusEnum.CrWorkpackagesInProgress &&
                      change.rejectionComment && (
                        <ListItem
                          alignItems="flex-start"
                          css={css`
                            padding-left: 0;
                            padding-bottom: 0;
                            background: #80acd452;
                          `}
                        >
                          <ListItemText
                            primaryTypographyProps={{
                              fontSize: '12px',
                              fontWeight: '600',
                            }}
                            primary={t('ReasonToChange')}
                            css={css`
                              width: 200px;
                            `}
                          />
                          <ListItemText
                            secondaryTypographyProps={{ align: 'left' }}
                            secondary={change?.rejectionComment}
                            css={css`
                              width: 100%;
                            `}
                          />
                        </ListItem>
                      )}

                    {isRejected && (
                      <ListItem
                        alignItems="flex-start"
                        css={css`
                          padding-left: 0;
                          padding-bottom: 0;
                          background: #80acd452;
                        `}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                          primary={
                            isRejected
                              ? t('ReasonToReject')
                              : t('ReasonToChange')
                          }
                          css={css`
                            width: 200px;
                          `}
                        />
                        <ListItemText
                          secondaryTypographyProps={{ align: 'left' }}
                          secondary={change?.rejectionComment}
                          css={css`
                            width: 100%;
                          `}
                        />
                      </ListItem>
                    )}

                    {isCheckStatus && (
                      <ListItem
                        alignItems="flex-start"
                        css={css`
                          padding-left: 0;
                          padding-bottom: 0;
                        `}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                          primary={`${t('salesValue')}:`}
                          css={css`
                            width: 200px;
                          `}
                        />
                        <ListItemText
                          secondaryTypographyProps={{ align: 'left' }}
                          secondary={change?.totalSalesValue || '-'}
                          css={css`
                            width: 100%;
                          `}
                        />
                      </ListItem>
                    )}
                    {isCheckStatus && (
                      <ListItem
                        alignItems="flex-start"
                        css={css`
                          padding-left: 0;
                          padding-bottom: 0;
                        `}
                      >
                        <ListItemText
                          primaryTypographyProps={{
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                          primary={`${t('implDate')}:`}
                          css={css`
                            width: 200px;
                          `}
                        />
                        <ListItemText
                          secondaryTypographyProps={{ align: 'left' }}
                          secondary={
                            change?.implementationTargetDate
                              ? new Date(
                                  change?.implementationTargetDate || '',
                                ).toLocaleDateString()
                              : '-'
                          }
                          css={css`
                            width: 100%;
                          `}
                        />
                      </ListItem>
                    )}
                  </List>
                </Grid>
              </Grid>
              {change && showButtonGroup && (
                <ButtonsGroup
                  isChangeExist={!!change}
                  changeStatus={change?.status || ''}
                  workPackages={workPackages}
                  change={change}
                  onClickBtn={e => {
                    e.stopPropagation();
                    setModalType(e.currentTarget.dataset.type as DialogType);
                  }}
                />
              )}
            </Grid>
          </Section>
        )}
        {isClosedStatus && (
          <Box>
            <TextField
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
                width: 200px;
              `}
              required
              id="changeNoWebTool"
              label={t('changeNumberWebtool')}
              defaultValue={change?.changeNoWebTool || ''}
              onChange={e => onChange(e.target.name, e.target.value || null)}
              variant="standard"
              name="changeNoWebTool"
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />

            <TextField
              onChange={e => onChange(e.target.name, e.target.value || null)}
              css={css`
                .MuiInput-underline:before {
                  border-bottom: 1px solid #ececec;
                }
                .MuiInputLabel-asterisk {
                  color: #ff0000;
                }
                margin-left: 20px;
              `}
              required
              id="webToolVersion"
              name="webToolVersion"
              label={t('webtoolVersion')}
              defaultValue={change?.webToolVersion || ''}
              variant="standard"
              InputProps={{
                style: {
                  paddingLeft: '16px',
                  paddingBottom: '6px',
                  marginBottom: '16px',
                  fontSize: '14px',
                },
              }}
              InputLabelProps={{
                style: {
                  paddingLeft: '20px',
                  fontWeight: 600,
                  color: '#333333',
                },
              }}
            />
          </Box>
        )}
      </Grid>

      {!isDataLoading &&
        processes &&
        change &&
        change.status !== UpdateChangeDtoStatusEnum.New &&
        change.status !== UpdateChangeDtoStatusEnum.NewRework &&
        change.status !== UpdateChangeDtoStatusEnum.Draft &&
        change.status !== UpdateChangeDtoStatusEnum.NewRejected &&
        processes
          .slice()
          .sort(
            (a, b) =>
              Number(
                getDepartmentById(
                  a.pId,
                  change.commonProject?.navProcessToCommonProjectRelations,
                )?.groupNo,
              ) -
              Number(
                getDepartmentById(
                  b.pId,
                  change.commonProject?.navProcessToCommonProjectRelations,
                )?.groupNo,
              ),
          )
          .map(process => (
            <ProcessItem
              key={process.id}
              department={getDepartmentById(
                process.pId,
                change.commonProject?.navProcessToCommonProjectRelations,
              )}
              process={process}
              onProcessClick={onProcessClick}
              workPackages={reducedWorkPackages[process.id] || []}
            />
          ))}

      <ChangeStatusDialogWrapper
        modalType={modalType}
        setModalType={m => setModalType(m)}
      />
    </Box>
  );
});

export default ChangePage;
